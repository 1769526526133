// i18next-extract-mark-ns-start main_page_hero
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import lodka from "../../images/lodka-hero.svg";

import { Trans, useI18next } from 'gatsby-plugin-react-i18next';

const HeroImage = () => {

    const { navigate } = useI18next();

    const goToTickets= () => navigate('/swieto-wisly-2023/bilety');


    return (
        <div className="container-fluid p-0 position-relative overflow-hidden" style={{ height: "500px" }}>
            <div
                className="position-absolute container-fluid h-100 p-0" style={{ height: "500px" }}
            >
                <StaticImage
                    src='../../images/sw23-back.jpg'
                    layout="fullWidth"
                    alt=""
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    transformOptions={{ fit: `cover` }}
                    style={{ height: '500px' }}
                />
            </div>
            <div className="position-absolute container-fluid h-100 p-0 bg-primary bg-opacity-75" ></div>
            <div className="position-absolute container-fluid h-100 p-0" >
                <div className="row h-100">
                    <div className="col-md-9">
                        <div className="container-fluid p-0 h-100 position-relative">
                            <div className="container-fluid p-0 h-100 position-absolute d-none">
                                <div className="row h-100 align-items-end">
                                    <div className="col offset-6">
                                        <img alt="" src={lodka} width={"100%"} />
                                    </div>
                                </div>
                            </div>
                            <div className="position-absolute top-50 translate-middle-y ps-3 pe-3 ps-md-5">
                                <h1>Święto Wisły</h1>
                                <h2><strong>2 września 2023</strong></h2>
                                <h3 className="py-1">Bezpłatne aktywności i atrakcje</h3>
                                <div className="pt-3">
                                    <button onClick={goToTickets} type="button" className="btn btn-secondary btn-lg mb-3 mb-sm-none">Rezerwacja</button>
                                    <span className="ps-3"></span>
                                    <a id="read-more-vistula-day" href="#about-vistula-day-section" role="button" className="btn btn-outline-light btn-lg mb-3 mb-sm-none">Czytaj więcej</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default HeroImage;