import React from 'react';
import CountBullet from './count-bullet';
const CountBullets = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <CountBullet count="18" title="jednostek" desc="łodzi tradycyjnych i motorówek na których będą odbywać się rejsy" />
                </div>
                <div className="col">
                    <CountBullet count="32" title="rejsy" desc="do których będzie można dołączyć" />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <CountBullet count="320" title="bezpłatnych biletów" desc="dostępnych w rezerwacji online oraz na miejscu w dniu wydarzenia" />
                </div>
                <div className="col">
                    <CountBullet count="14" title="atrakcji" desc="w 3 lokalizacjach: warsztaty i stoiska promocyjne, rejsy, występy taneczne i koncert na statku" />
                </div>
            </div>
        </div>
    );
}

export default CountBullets;