import { faBridgeWater, faEarListen, faFaceLaughBeam, faMusic, faPeopleGroup, faPersonWalkingDashedLineArrowRight, faSailboat, faShip, faTents, faWaterLadder } from '@fortawesome/free-solid-svg-icons';
import { navigate } from 'gatsby';
import React from 'react';
import PageSection from '../page-section';
import EventListItem from './event-list-item';
import { faSun } from '@fortawesome/free-regular-svg-icons';
const EventsListSection = () => {
    const goToTickets= () => navigate('/swieto-wisly-2023/bilety');
    const goToFacebookEvent = () => {
        if (typeof window !== `undefined`) window.open("https://www.facebook.com/events/593998082365851/", '_blank');
     }; 
    return (
        <PageSection className='bg-white text-black' id="events-section" data-bs-spy="scroll" data-bs-target="#read-more-events">
            <h2><strong>Atrakcje</strong> Święta Wisły 2023</h2>
            <div className="py-3" ></div>
            <EventListItem
                icon={faTents}
                eventTitle="Warsztaty i stoiska promocyjne"
                desc="Przy Płycie Desantu odbędzie się wyjątkowe wydarzenie. Od 12:00 do 17:00 czekać na Was będą warsztaty: tworzenie łódeczek, malowanie kamieni, mydełka z roślinami, moda wilanowska, kajak polo i szydełkowanie. Posmakujecie powideł i tradycyjnej zupy urzeczańskiej. A o 16:00 odbędzie się występ taneczny grupy Woda na Młyn oraz kwartetu Odpoczno. Poza tym liczne rejsy edukacyjne i rekreacyjne z Pomostu 511."
                buttonText="Więcej"
                sectionLink={true}
                sectionId="#more-section-village"
                buttonId={"read-more-village"}
            />
            <EventListItem
                icon={faShip}
                eventTitle="Rejsy rekreacyjne"
                desc="W godzinach między 11:00 a 17:00, oferujemy możliwość uczestnictwa w 30-minutowych rejsach na pokładach dwóch statków: Galar Odrzański oraz Szkuta Romantyczna. Rejsy przeznaczone dla osób poszukujących wyjątkowej perspektywy na Warszawę i spokojnej atmosfery."
                buttonText="Rezerwuj"
                buttonOnClick={goToTickets}
            />
            <EventListItem
                icon={faSailboat}
                eventTitle="Parada statków"
                desc="Uroczysta parada statków, która odbędzie się na plaży Saska Kępa. Serdecznie zachęcamy do udziału w godzinach 18:00 - 19:00 (wejście na jednostki od 17:30). Podziwiać będzie można bogactwo tradycyjnych łodzi drewnianych, motołódek, motorówek, ibarek oraz omeg, prezentujących zróżnicowany charakter żeglugi na wodach Wisły."
                buttonText="Rezerwuj"
                buttonOnClick={goToTickets}
            />
            <EventListItem
                icon={faBridgeWater}
                eventTitle="Rejsy edukacyjno-przyrodnicze"
                desc='Fascynujące rejsy edukacyjno-przyrodnicze na pokładzie Szkuty Romantycznej, rozpoczynające się i kończące przy Pomoście 511. Pierwszy z nich o opowie o przyrodniczych sekretach brzegów Wisły. Drugi to prawdziwa lekcja ekologii i świadomości funkcjonowania rzeki. Eksperymenty wyjaśnią ekosystem Wisły, jej znaczenie oraz wyzwania ekologiczne.'
                buttonText="Rezerwuj"
                buttonOnClick={goToTickets}
            />
            <EventListItem
                icon={faSun}
                eventTitle="Rejs o wschodzie słońca"
                desc='Rejs o wschodzie słońca pod nazwą "Urzeczańskie pożegnanie lata", który odbędzie się na łodzi Łyżwa Wisła, startując z Plaży Saska Kępa. Podczas rejsu, pod opieką eksperta Jacka Marczewskiego, wyruszymy w kierunku północno-zachodnich granic Warszawy, przemierzając malownicze tereny Zawad i Urzecza. Rejs ma charakter edukacyjny, podczas którego ekspercki komentarz Pana Marczewskiego pozwoli zgłębić tajniki tego obszaru. To wyjątkowa okazja do odkrywania lokalnej historii i przyrody, podczas spokojnego porannego rejsu.'
                buttonText="Rezerwuj"
                buttonOnClick={goToTickets}
            />
            <EventListItem
                icon={faPeopleGroup}
                eventTitle="Występ taneczny"
                desc="Niezwykły spektakl artystyczny, składający się z trzech pełnych emocji aktów. Pełne emocji wydarzenie zapewni grupa Woda na Młyn w około 40-osobowym składzie oraz kwartet Odpoczno."
                buttonOnClick={goToTickets}
            />
            <EventListItem
                icon={faMusic}
                eventTitle="Koncert na statku"
                desc="Wyjątkowy koncert na pokładzie statku Panama, przy urokliwej Plaży Saska Kępa. Wydarzenie jest otwarte dla uczestników zgromadzonych zarówno na plaży, jak i na jednostkach pływających biorących udział w paradzie."
            />
        </PageSection>
    );
}

export default EventsListSection;