import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const MobileLogotypesSection = () => {
    return (
        <div className='container-fluid bg-light d-flex flex-wrap flex-sm-nowrap justify-content-evenly align-items-center mobile-logos' >
            <div>
                <StaticImage
                    src='../../images/logos/dark/rosaventi_logo_dark.png'
                    layout="constrained"
                    alt=""
                    height={50}
                    transformOptions={{ fit: `inside` }}
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    objectFit='contain'
                    className='my-2 mx-3'
                />
            </div>
            <div>
                <StaticImage
                    src='../../images/logos/dark/zzw-nowe-logo-dark.png'
                    layout="constrained"
                    alt=""
                    height={50}
                    transformOptions={{ fit: `inside` }}
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    objectFit='contain'
                    className='my-2 mx-3'
                />
            </div>
            <div>
                <StaticImage
                    src='../../images/logos/dark/warszawa-logo-dark.png'
                    layout="constrained"
                    alt=""
                    height={50}
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    objectFit='contain'
                    className='my-2 mx-3'
                />
            </div>
            <div >
                <StaticImage
                    src='../../images/logos/dark/inpost-logo-dark.png'
                    layout="constrained"
                    alt=""
                    height={40}
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    objectFit='contain'
                    className='my-2 mx-3'
                />
            </div>

            <div >
                <StaticImage
                    src='../../images/logos/dark/miami-wars-logo-dark.png'
                    layout="constrained"
                    alt=""
                    height={25}
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    objectFit='contain'
                    className='my-2 mx-3'
                />
            </div>

            <div >
                <StaticImage
                    src='../../images/logos/dark/cud-nad-wisla-logo-dark.png'
                    layout="constrained"
                    alt=""
                    height={35}
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    objectFit='contain'
                    className='my-2 mx-3'
                />
            </div>

            <div >
                <StaticImage
                    src='../../images/logos/dark/meloradio-logo-dark.png'
                    layout="constrained"
                    alt=""
                    height={16}
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    objectFit='contain'
                    className='my-2 mx-3'
                />
            </div>

            <div >
                <StaticImage
                    src='../../images/logos/dark/wisla-logo-dark.png'
                    layout="constrained"
                    alt=""
                    height={50}
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    objectFit='contain'
                    className='my-2 mx-3'
                />
            </div>

        </div>
    )
}

export default MobileLogotypesSection
